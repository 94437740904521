<template>
  <div class="flip-card">
    <v-card color="grey lighten-4" flat tile class="ml-3 mr-3">
      <v-toolbar dense rounded outlined>
        <v-btn @click="reload" v-if="inEdit" plain>
          <v-icon>mdi-autorenew</v-icon>
          <span class="ml-1" v-t="'reload'"></span>
        </v-btn>
        <v-btn @click="podioSync" plain v-if="!podio.showPodioSyncInput">
          <v-icon>mdi-web-sync</v-icon>
          <span class="ml-1">Podio Sync</span>
        </v-btn>
        <v-text-field
          v-if="podio.showPodioSyncInput"
          v-model="podio.value"
          outlined
          clearable
          dense
          hide-details="auto"
          :label="$t('insertLink')"
          autocomplete="nope"
          @keyup.enter="doApply">
          <v-icon style="cursor: pointer;" slot="append" color="blue" @click="doApply">mdi-web-sync</v-icon>
        </v-text-field>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="openDeleteModal" v-if="!inEdit" color="red" plain>
            <v-icon>mdi-trash-can-outline</v-icon>
            <span class="ml-1" v-t="'delete'"></span>
          </v-btn>
        </div>
        <div>
          <v-btn @click="openB2BCredentialsConfirm" v-if="!inEdit" color="primary" plain>
            <v-icon>mdi-key</v-icon>
            <span class="ml-1">{{ $t('b2bCredentialsHeader') }}</span>
          </v-btn>
        </div>
        <div>
          <v-btn @click="showAnchorModal" v-if="!inEdit" color="primary" plain>
            <v-icon>mdi-anchor</v-icon>
            <span>{{ $t('anchors') }}</span>
          </v-btn>
        </div>
        <div>
          <v-btn @click="showIamUsersModal" v-if="!inEdit" color="primary" plain>
            <v-icon>mdi-account-multiple</v-icon>
            <span class="ml-1" v-t="'users'"></span>
          </v-btn>
        </div>
        <div>
          <v-btn @click="update" v-if="!inEdit" color="primary" plain>
            <v-icon>mdi-pencil</v-icon>
            <span class="ml-1" v-t="'update'"></span>
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>

    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          <span>{{ $t('deleteHeader') }}</span>
        </v-card-title>
        <v-card-text class="black-text">
          {{ $t('deleteMessage') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false"><span>{{ $t('close') }}</span></v-btn>
          <v-btn color="red" text @click="confirmDelete"><span>{{ $t('delete') }}</span></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="b2bCredentialsConfirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          <span>{{ $t('b2bCredentialsHeader') }}</span>
        </v-card-title>
        <v-card-text class="black-text">
          <span>{{ $t('b2bCredentialsMessage') }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="cancelB2BCredentials"><span>{{ $t('cancel') }}</span></v-btn>
          <v-btn color="primary" text @click="confirmB2BCredentials"><span>{{ $t('confirm') }}</span></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="b2bCredentialsResultDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          <span>{{ $t('b2bCredentialsHeader') }}</span>
        </v-card-title>
        <v-card-text>
          <div>{{ b2bCredentialsResult }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeB2BCredentialsResult"><span>{{ $t('cancel') }}</span></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-8">
      <div class="scene scene--card">
        <div class="card" v-bind:class="{ flipme: cardOne == 'flipped' }">
          <div class="card__face card__face--front">
            <CustomerView :key="anchorCollection.length" v-if="!inEdit" :customerId="customerId"></CustomerView>
          </div>
          <div class="card__face card__face--back">
            <CustomerUpdate v-if="!!inEdit" :customerId="customerId" @saveCustomer="update"></CustomerUpdate>
          </div>
        </div>
      </div>
    </div>
    <v-dialog transition="dialog-bottom-transition" max-width="1200" v-model="iamUserModalIsVisible" persistent>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="iamUserModalIsVisible = !iamUserModalIsVisible">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-t="'users'"></v-toolbar-title>
        </v-toolbar>
      </v-card>
      <IamUsersTable :iamTraitName="defaultTraitName" :iamTraitCustomValue="iamTraitCustomValue" :customerId="customerId" />
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="1200" v-model="anchorModalIsVisible" persistent>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="anchorModalIsVisible = !anchorModalIsVisible">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-t="'anchors'"></v-toolbar-title>
        </v-toolbar>
      </v-card>
      <v-data-table :headers="headers" :items="anchorCollection.slice().reverse()" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text block color="primary" dark class="mb-2" v-bind="attrs" v-on="on">{{ $t('newParam', {param:$t('anchor')}) }}</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ $t('newParam', {param:$t('anchor')}) }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field dense v-model="editedItem.motivationDescription" :label="$t('description')"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close" v-t="'cancel'"></v-btn>
                  <v-btn color="blue darken-1" text @click="save" v-t="'confirm'"></v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        
        <template v-slot:item.done="{ item }">
          <BooleanIcon :booleanValue="item.done" :alt="''+item.done"/>
        </template>
        <template v-slot:item.motivationCode="{ item }">
          <BooleanIcon v-if="!item.motivationCode" :booleanValue="!!item.motivationCode" alt="custom"/>
          <span v-else>{{ item.motivationCode }}</span>
        </template>
        <template v-slot:no-data>
          <v-btn text color="primary" @click="initialize" v-t="noDataRefresh"></v-btn>
        </template>
      </v-data-table>
    </v-dialog>

    <!--v-speed-dial v-if="!!showWarmholeFab" v-model="wormholeFab" :right="true" :bottom="true" direction="left" transition="slide-x-reverse-transition" fixed>
      <template v-slot:activator>
        <v-btn v-model="wormholeFab" color="primary" dark fab style="overflow: hidden;">
          <v-icon v-if="wormholeFab">mdi-close</v-icon>
          <v-img v-else :src="require('@/assets/wormhole.png')" style="width: 7px; height: auto; margin: 6px 1px 0 1px;" />
        </v-btn>
      </template>
      <v-row style="max-height: 60px; width: 50vw;">
        <v-col cols="12" sm="1" style="background-color: #35838f; padding: 1px 0; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <v-img :src="require('@/assets/businesscentral_logo.png')" style="width: 50px; height: auto;" />
        </v-col>
        <v-col cols="12" sm="11" style="padding: 1px 0; background-color: #fff;">
          <div style="display: flex; flex-direction: row; justify-content: start; align-items: center; height: 80%; margin: 5px 0px; border-top: #d3d6da 1px solid; border-bottom: #d3d6da 1px solid;">
            <div style="display:flex; flex-direction: row; justify-content: center; align-items: center; font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;-webkit-font-smoothing: antialiased; font-size: 14px; font-weight: 400; color: #323130; vertical-align: middle; margin: 0 5px; cursor: pointer;" @click="forceSyncWithBc()">
              <v-img :src="require('@/assets/bc/Action_RefreshLines_mono_16x16.png')" style="width: 16px; height: 16px; margin-top: -2px; margin-right: 2px;" />
              {{ $t('forceSync') }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-speed-dial-->
  </div>
</template>

<script>

import { get, call, sync } from "vuex-pathify";
import CustomerUpdate from "@/components/view/CustomerUpdate";
import CustomerView from "../../components/view/CustomerView";
import BooleanIcon from "../../components/app/BooleanIcon.vue";
import IamUserTable from "../../components/IamUsersTable";

export default {
  components: {
    CustomerView,
    CustomerUpdate,
    IamUserTable,
    BooleanIcon,
  },
  props: ["customerId"],
  data: () => ({
    inEdit: false,
    cardOne: "start",
    iamUserModalIsVisible: false,
    iamTraitCustomValue: "",
    anchorModalIsVisible: false,
    anchorCollection: [],
    dialog: false,
    deleteDialog: false,
    editedItem: { done: false, motivationDescription: "" },
    defaultItem: { done: false, motivationDescription: "" },
    iamTraitCustomValue: "",
    podio: {
      showPodioSyncInput: false,
      value: ""
    },
    wormholeFab: null,
    b2bCredentialsConfirmDialog: false,
    b2bCredentialsResultDialog: false,
    b2bCredentialsResult: {}
  }),
  methods: {
    openB2BCredentialsConfirm() {
      this.b2bCredentialsConfirmDialog = true;
    },
    cancelB2BCredentials() {
      this.b2bCredentialsConfirmDialog = false;
    },
    confirmB2BCredentials() {
      this.$store.dispatch("customers/apiCustomersIdToPrestashopB2BPost", this.customerId)
          .then(result => {
            this.b2bCredentialsResult = result;
            this.b2bCredentialsResultDialog = true;
          })
          .catch(error => {
            console.error("Errore nella creazione delle credenziali B2B", error);
          });
      this.b2bCredentialsConfirmDialog = false;
    },
    closeB2BCredentialsResult() {
      this.b2bCredentialsResultDialog = false;
    },
    openDeleteModal() {
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.deleteDialog = false;
      this.apiCustomersIdDelete(this.customerId)
          .then(res => {
            console.log("Customer eliminato");
            this.$router.push('/customers'); // Reindirizza alla rotta /customers
          })
          .catch(error => {
            console.error("Errore durante l'eliminazione:", error);
          });
    },
    podioSync: function() {
      this.podio.showPodioSyncInput = true;
    },
    doApply: function() {
      this.apiCustomerSyncIdPost({
        id: this.customerId,
        urlPage: this.podio.value
      })
      this.podio.value = "";
      this.podio.showPodioSyncInput = false;
    },
    update: function () {
      this.inEdit = !this.inEdit;
      this.cardOne == "start" ? (this.cardOne = "flipped") : (this.cardOne = "start");
    },
    reload: function () {
      this.$window.location.reload();
    },

    showAnchorModal() {
      this.initialize();
      this.anchorModalIsVisible = true;
    },
    showIamUsersModal() {
      this.iamTraitCustomValue = this.customers.find(x => x.id == this.customerId)?.code || '';
      this.iamUserModalIsVisible = true;
    },
    
    ...call('iam/*'),
    ...call('customers/*'),
    ...call('vats/*'),
    ...call('wormhole/*'),

    initialize() {
      this.anchorCollection.length = 0;
      this.initVatCollection();
      this.apiCustomersIdAnchorsGet(this.customerId)
        .then(res => {
          this.anchorCollection.push(...res);
        })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },

    save() {
      this.apiCustomersIdAddAnchorPost({
        entityId: this.customerId,
        entityType: "Customer",
        ...this.editedItem
      }).finally(this.initialize)
      this.close()
    },
    forceSyncWithBc() {
      this.apiBCCustomerSyncIdPost(this.customerId);
    },
  },
  computed: {
    headers(){
      return [
        { text: this.$t('code'), align: 'start', sortable: false, value: 'motivationCode' },
        { text: this.$t('description'), align: 'start', sortable: false, value: 'motivationDescription' },
        { text: this.$t('done'), align: 'start', sortable: false, value: 'done' },
      ]
    },
    customers: get("customers/allCustomers"),
    defaultTraitName: get("tenantConfiguration/defaultTraitName"),
    showWarmholeFab: get("tenantConfiguration/showWarmholeFab"),
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  created() {
    this.initialize();
  },
};
</script>

<style>
.black-text {
  color: black !important;
}
.card {
  width: 100%;
  height: 100%;
  transition: transform 0.7s;
  transform-style: preserve-3d;
  position: relative;
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card__face--front {
  background: red;
}

.card__face--back {
  background: blue;
  transform: rotateY(180deg);
}

.flipme {
  transform: rotateY(180deg);
}
</style>
